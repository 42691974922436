// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fitness-culture-js": () => import("./../src/pages/fitness-culture.js" /* webpackChunkName: "component---src-pages-fitness-culture-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mov-3-js": () => import("./../src/pages/mov3.js" /* webpackChunkName: "component---src-pages-mov-3-js" */),
  "component---src-pages-my-story-js": () => import("./../src/pages/my-story.js" /* webpackChunkName: "component---src-pages-my-story-js" */),
  "component---src-pages-vip-js": () => import("./../src/pages/vip.js" /* webpackChunkName: "component---src-pages-vip-js" */)
}

